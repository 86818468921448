import type { GetStaticProps, NextPage } from 'next';

import { GetFeaturedFunds, GetFeaturedOrgs, GetTotalDonations, GetTotalGrants } from '@endaoment-frontend/api';
import { makeDehydratedQueries } from '@endaoment-frontend/data-fetching';

import { MetaTags } from '../components/MetaTags';
import { PageLayout } from '../components/PageLayout';
import { TestimonialCarousel } from '../components/TestimonialCarousel';
import {
  ForAdvisorsFeatureSection,
  ForDonorsFeatureSection,
  ForNonprofitsFeatureSection,
  Hero,
  PortfoliosFeatureSection,
  UpgradeSection,
} from '../content/home';
import { homePage } from '../metadata';

import styles from './index.module.scss';

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      dehydratedState: await makeDehydratedQueries(
        GetTotalDonations.getDehydratedQueryArgs([]),
        GetTotalGrants.getDehydratedQueryArgs([]),
        GetFeaturedFunds.getDehydratedQueryArgs([]),
        GetFeaturedOrgs.getDehydratedQueryArgs([]),
      ),
    },
    // ISR every 10 minutes
    revalidate: 10 * 60,
  };
};

const Home: NextPage = () => {
  return (
    <PageLayout>
      <MetaTags data={homePage} />
      <Hero />
      <div className={styles.index__container}>
        <ForDonorsFeatureSection />
        <PortfoliosFeatureSection />
        <ForAdvisorsFeatureSection />
        <ForNonprofitsFeatureSection />
        <UpgradeSection />
        <TestimonialCarousel />
      </div>
    </PageLayout>
  );
};

export default Home;
